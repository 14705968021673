<template>
  <div class="contact">
    <section class="banner" :class="{'active': mounted}">
      <div class="parallax-banner" id="scene">
        <div class="banner-img"></div>
      </div>
      <div class="title">
        <div class="underline"></div>
        <div class="title-text"><span>Contact</span></div>
      </div>
    </section>

    <section class="content">
      <div class="container">
        <div class="outer px-5 px-lg-0 mx-auto">
          <div class="px-lg-5 mb-4">
            <h5 class="text-lg-center mb-0">聯絡我們</h5>
            <h6 class="text-lg-center">Contact us</h6>
          </div>

          <div class="inner">
            <!-- <div class="inner-line">
              <p>Line ID</p>
              <p>DFCD</p>
            </div>
            <div class="inner-line">
              <p>E-mail</p>
              <p>DFCD@dfcd.com</p>
            </div> -->
            <div class="mb-4">
              <p class="mb-1">Address</p>
              <p class="mb-1">台北市中山區建國北路二段147號8樓<br/>8F., No. 147, Sec. 2, Jianguo N. Rd., Zhongshan Dist., Taipei City, Taiwan (R.O.C.)</p>
            </div>
            <div class="">
              <p class="mb-1">Phone</p>
              <p class="mb-1">+886 937-358-778<br/>+886 2 2772-0203</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <DFCDFooter></DFCDFooter>
  </div>
</template>

<script>
import DFCDFooter from '@/components/DFCDFooter.vue';
import simpleParallax from 'simple-parallax-js';

export default {
  name: 'Contact',
  components: {
    DFCDFooter,
  },
  data() {
    return {
      mounted: false,
    }
  },
  props: {
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 600);
    
    setTimeout(() => {
      const scene = document.getElementById('scene');
      const p = new simpleParallax(scene, {
        orientation: 'down',
        scale: 1.5
      });
      console.log(p);
    }, 500);
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";

  .banner {
    position: relative;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    overflow: hidden;

    .parallax-banner {
      position: relative;
      width: 100%;
      height: 100dvh;
      height: 100vh;
      .banner-img {
        width: 105%;
        height: 100%;
        background-image: url('~@/assets/images/About_01.jpg');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        transition: opacity 2s ease;
        // transform: translate(0%, 0);
        opacity: 0;
      }
    }

    .title {
      position: absolute;
      bottom: 15%;
      right: 6rem;
      width: 30%;
      display: flex;
      align-items: flex-end;
      column-gap: 1rem;
      @include smaller-than-medium {
        right: 15%;
        left: 0%;
        width: initial;
      }
      .underline {
        flex: 1 1;
        position: relative;
        &:after {
          display: block;
          content: '';
          width: 0;
          transition: all .8s .5s ease;
          border-bottom: solid 2px $color-link-black;
        }
        margin-bottom: .5rem;
      }
      .title-text {
        flex: 0 0 auto;
        overflow: hidden;
        span {
          font-size: 1.5rem;
          display: inline-block;
          transform: translate(0, 100%);
          transition: all .8s 1.3s ease;
        }
      }
    }

    &.active {
      .banner-img {
        // transform: translate(-4.7%, 0);
        opacity: 1;
      }
      .title {
        .underline {
          &:after {
            width: 100%;
          }
        }
        .title-text {
          span {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .content {
    .outer {
      padding: 4rem 0 8rem 0;
      width: 75%;
      position: relative;
      display: flex;
      justify-content: center;
      h2 {
        padding: 0 1rem;
      }
      .inner {
        padding-left: 2rem;
        border-left: solid 2px #D9D9D9;
        @include smaller-than-medium {
          padding-left: 1.2rem;
        }
        .inner-line {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: .5rem;
          }
          p {
            margin: 0;
            flex: 0 0 auto;
            &:first-child {
              flex: 0 0 4rem;
            }
          }
        }
      }

      @include smaller-than-medium {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;

        h2 {
          flex: 0 0 100%;
          padding: 0;
          margin-bottom: 2rem;
        }
        .inner {
          flex: 0 0 100%;
          .inner-line {

            width: 100%;
            p {
              flex: 1 1;
              &:first-child {
                flex: 0 0 4rem;
              }
            }
          }
        }
      }
    }
  }

</style>
